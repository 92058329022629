class EditorMode {
  public static get create() {
    return EditorMode._create;
  }
  private static _create = new EditorMode();
  public static get edit() {
    return this._edit;
  }
  private static _edit = new EditorMode();
  public static get duplicate() {
    return this._duplicate;
  }
  private static _duplicate = new EditorMode();
  public static get default() {
    return EditorMode.create;
  }

  get isCreate(): boolean {
    return this === EditorMode._create;
  }

  get isEdit(): boolean {
    return this === EditorMode._edit;
  }

  get isDuplicate(): boolean {
    return this === EditorMode._duplicate;
  }
}

export default EditorMode;
