












import Vue from 'vue';
import Place from '@/dtos/place';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    location: {
      type: Place,
      required: false,
      default() {
        return Place.void();
      },
    },
  },
  data() {
    return {
      selectedLocation: null as { [key: string]: any } | null,
      typedInLocation: '',
    };
  },
  watch: {
    location() {
      this.populate();
    },
  },
  mounted() {
    this.populate();
  },
  methods: {
    populate() {
      this.selectedLocation = {
        latitude: this.location.latitude,
        longitude: this.location.longitude,
      };
      this.typedInLocation = this.location.description;
    },
    onLocationSelect(l: any) {
      this.selectedLocation = l;
    },
    onLocationInput(value: string) {
      this.typedInLocation = value;
      if (
        this.selectedLocation &&
        !value.includes(this.selectedLocation.locality)
      ) {
        this.selectedLocation = null;
      }
      this.exposeLocation();
    },
    exposeLocation() {
      const p = Place.void();
      p.description = this.typedInLocation;
      if (this.selectedLocation) {
        p.latitude = this.selectedLocation.latitude;
        p.longitude = this.selectedLocation.longitude;
      }
      this.$emit('change', p);
    },
  },
});
