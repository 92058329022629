import firebase from 'firebase';

interface Defaults {
  eventPictures: { [key: string]: string };
  tourPictures: { [key: string]: string };
}

const defaults = async (): Promise<Defaults> => {
  return firebase
    .firestore()
    .collection('defaults')
    .get()
    .then(
      (
        snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
      ) => {
        if (!snap.docs.length) return { eventPictures: {}, tourPictures: {} };
        return snap.docs[0].data() as Defaults;
      }
    );
};

const takeRandom = (array: string[]): string =>
  array[Math.floor(Math.random() * array.length)];

export const defaultTourImageURL = async (): Promise<string> => {
  return defaults()
    .then((d) => Object.values(d.tourPictures))
    .then(takeRandom);
};

export const defaultEventImageURL = async (): Promise<string> => {
  return defaults()
    .then((d) => Object.values(d.eventPictures))
    .then(takeRandom);
};
