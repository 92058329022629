


























import Vue from 'vue';

export const RideeHints = {
  descriptionLinks: 'rideeDescriptionLinksHint',
};

const hintIDMessageMap: any = {
  rideeDescriptionLinksHint: 'descriptionLinksHintMessage',
};

export default Vue.extend({
  data() {
    return {
      doNotShowAgain: false,
    };
  },
  props: {
    hintID: {
      type: String,
      required: false,
      default: null,
    },
  },
  watch: {
    hintID: function (newHintID) {
      if (newHintID && !localStorage[newHintID]) this.showHint();
    },
  },
  computed: {
    message(): string {
      const messageKey = hintIDMessageMap[this.hintID];
      if (messageKey) return this.$t(messageKey).toString();
      return '';
    },
  },
  methods: {
    showHint() {
      this.$modal.show('hint');
    },
    hideHint() {
      this.$modal.hide('hint');
      if (this.doNotShowAgain) {
        localStorage[this.hintID] = true;
      }
      this.$emit('close');
    },
  },
});
