import SharedFields from '@/add/shared.fields';
import Place from '@/dtos/place';
import { tomorrowIfSundayOrNextWeekend } from '@/utils/date_time';

class BaseFields {
  private _shared?: SharedFields;

  private _title = '';
  get title() {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
    if (this._shared) this._shared.title = value;
  }

  private _description = '';
  get description() {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
    if (this._shared) this._shared.description = value;
  }

  private _startDateTime = tomorrowIfSundayOrNextWeekend();
  get startDateTime() {
    return this._startDateTime;
  }
  set startDateTime(value: Date) {
    const oldDate = new Date(this._startDateTime.getTime());
    this._startDateTime = value;
    if (this._shared) this._shared.startDateTime = value;
    this.startDateTimeDidChange(value, oldDate);
  }

  private _location = Place.void();
  get location() {
    return this._location;
  }
  set location(value: Place) {
    this._location = value;
    if (this._shared) this._shared.location = value;
  }

  constructor(shared?: SharedFields) {
    this._shared = shared;

    if (shared?.title) this.title = shared.title;
    if (shared?.description) this.description = shared.description;
    if (shared?.location) this.location = shared.location;
    if (shared?.startDateTime) this.startDateTime = shared.startDateTime;
  }

  protected startDateTimeDidChange(newDate: Date, oldDate: Date) {
    return;
  }
}

export default BaseFields;
