import Place from '@/dtos/place';

class SharedFields {
  title?: string;
  description?: string;
  startDateTime?: Date;
  location?: Place;
}

export default SharedFields;
